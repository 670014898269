@font-face {
  font-family: Poppins-Regular;
  src: url(../fonts/Poppins/Poppins-Regular.ttf);
}
@font-face {
  font-family: Poppins-Medium;
  src: url(../fonts/Poppins/Poppins-Medium.ttf);
}
@font-face {
  font-family: Poppins-Bold;
  src: url(../fonts/Poppins/Poppins-Bold.ttf);
}
body,
html {
  /* background-color: #fbfcfd; */
  background: #f4f6f3;
  height: 100%;
  margin: 0px;
  padding: 0px;
  font-size: 13px !important;
  font-weight: 400;
  font-family: "Poppins", Helvetica, "sans-serif";
}

@media only screen and (max-width: 1200px) {
  body,
  html {
    font-size: 11px !important;
  }
}

@media only screen and (max-width: 600px) {
  body,
  html {
    font-size: 9px !important;
  }
}

body {
  display: flex;
  flex-direction: column;
  color: #464e5f;
}

.root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* Content */
.content {
  border: 1px solid #e5e5e5;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.1),
    0px 3px 4px 0px rgba(0, 0, 0, 0.05), 0px 1px 8px 0px rgba(0, 0, 0, 0.02);
}

/* Alert Bar */

.MuiAlert-filledInfo {
  color: #fff;
  font-weight: 500;
  background-color: #f2994a;
}
.MuiAlert-root {
  display: flex;
  padding: 6px 16px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.43;
  border-radius: 4px;
}
.MuiPaper-elevation0 {
  box-shadow: none;
}

.MuiAlert-icon {
  display: flex;
  opacity: 0.9;
  padding: 7px 0;
  font-size: 22px;
  margin-right: 12px;
}

.MuiAlert-message {
  padding: 8px 0;
}

.MuiAlert-message h6 {
  font-size: 1.175rem;
  font-weight: 600;
}

.test-result {
  font-size: 1.5rem;
  font-weight: 500;
}
