@font-face {
  font-family: Poppins-Regular;
  src: url("Poppins-Regular.fd8c1383.ttf");
}

@font-face {
  font-family: Poppins-Medium;
  src: url("Poppins-Medium.7f5202f0.ttf");
}

@font-face {
  font-family: Poppins-Bold;
  src: url("Poppins-Bold.ef368a79.ttf");
}

body, html {
  background: #f4f6f3;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Poppins, Helvetica, "sans-serif";
  font-weight: 400;
  font-size: 13px !important;
}

@media only screen and (width <= 1200px) {
  body, html {
    font-size: 11px !important;
  }
}

@media only screen and (width <= 600px) {
  body, html {
    font-size: 9px !important;
  }
}

body {
  color: #464e5f;
  flex-direction: column;
  display: flex;
}

.root {
  flex-direction: column;
  height: 100%;
  display: flex;
}

.content {
  border: 1px solid #e5e5e5;
  box-shadow: 0 3px 3px -2px #0000001a, 0 3px 4px #0000000d, 0 1px 8px #00000005;
}

.MuiAlert-filledInfo {
  color: #fff;
  background-color: #f2994a;
  font-weight: 500;
}

.MuiAlert-root {
  border-radius: 4px;
  padding: 6px 16px;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.43;
  display: flex;
}

.MuiPaper-elevation0 {
  box-shadow: none;
}

.MuiAlert-icon {
  opacity: .9;
  margin-right: 12px;
  padding: 7px 0;
  font-size: 22px;
  display: flex;
}

.MuiAlert-message {
  padding: 8px 0;
}

.MuiAlert-message h6 {
  font-size: 1.175rem;
  font-weight: 600;
}

.test-result {
  font-size: 1.5rem;
  font-weight: 500;
}

/*# sourceMappingURL=index.b97a52ad.css.map */
